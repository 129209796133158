import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllChats, connectSocket, logoutSocket } from 'socket/socket';
import { meDataServ } from './appApi';
import { meAuthServ } from '../user/userApi';
import {
  getMeFailed,
  getMeSuccess,
  getUserPartners,
  userIsLoaded
} from '../user/userSlice';
import { getShortcuts } from '../partner/shortcut/shortcutSlice';
import { getAgentsByPartnerId } from '../partner/agents/agentsSlice';
import { getPartnersCount, getUsersCount } from '../super_admin/counts/countsSlice';
import { getCurrentPartner } from '../current_partner/currentPartnerSlice';
import { getAdmins } from '../super_admin/admins/adminsSlice';
import { getPartnerMeSuccess } from '../user_partner/userPartnerSlice';

const initialState = {
  isSidebarOpened: localStorage.getItem('sidebar_open') === null ? true : !!(+localStorage.getItem('sidebar_open')),
  settingItemsOpened: false,
  teamItemsOpened: false,
};

export const loginSuperAdmin = createAsyncThunk(
  'login_super_admin',
  async (empty, {dispatch})=> {
    const accessToken = localStorage.getItem('superadmin_token');

    if (accessToken) {
      dispatch(getUsersCount());
      dispatch(getPartnersCount());
      dispatch(getAdmins());

      const res = await meDataServ();
      dispatch(getMeSuccess(res.data));
    }
    else {
      dispatch(userIsLoaded())
    }
  }
);

export const loginStepOne = createAsyncThunk(
  'login_step_one',
  async (empty, {dispatch})=> {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      try{
        dispatch(getUserPartners());
        const res = await meDataServ();
        dispatch(getMeSuccess(res.data));
      }
      catch(err){
        dispatch(getMeFailed(err.data));
        localStorage.clear();
      }
    }
    else{
      dispatch(userIsLoaded())
    }
  }
);

export const loginStepTwo = createAsyncThunk(
  'login_step_two',
  async (partnerId, {dispatch})=> {
    try{
      const response = await meAuthServ(partnerId);
      dispatch(getPartnerMeSuccess(response.data));
      const userPartner = response.data;
      connectSocket(partnerId, userPartner.userId, userPartner.role, userPartner.name);

      dispatch(getShortcuts(partnerId));
      getAllChats(dispatch);

      dispatch(getCurrentPartner(partnerId));
      dispatch(getAgentsByPartnerId(partnerId));
    }
    catch(err){
      dispatch(getMeFailed())
      throw new Error("meAuthServ Error", err.message)
    }
  }
);

export const throwOutAgent = createAsyncThunk(
  'get_partner_key',
  async (userId, {getState})=> {
    const {currentPartner, userPartner} = getState();
    if(userId === userPartner.userData._id) {
      localStorage.removeItem(currentPartner.currentPartner.key);
      logoutSocket();
      const protokol = process.env.NODE_ENV === 'production' ? 'https' : 'http';
      window.location.href = `${protokol}://${process.env.REACT_APP_DOMAIN}/partners`;
    }
  }
)

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleSidebar: (state)=> {
      localStorage.setItem("sidebar_open", +(!state.isSidebarOpened));
      state.isSidebarOpened = !state.isSidebarOpened;
    },
    settingItemsOpen: (state, action)=> {
      state.settingItemsOpened = action.payload;
    },
    teamItemsOpen: (state, action)=> {
      state.teamItemsOpened = action.payload;
    },
  },
});

export const {
  toggleSidebar,
  settingItemsOpen,
  teamItemsOpen,
} = appSlice.actions;
export const selectApp = (state) => state.app;
export default appSlice.reducer;
