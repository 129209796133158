import * as S from "./PartnerForm.styles";
import React from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import DialogTitle from "@material-ui/core/DialogTitle";
import { initialValues, validationSchema, validationSchemaUpdate } from "./PartnerForm.config";
import { Button } from "ui";
import { editPartner, addPartner } from "redux_toolkit/reducers/super_admin/partners/partnersSlice";
import { hideModal } from "redux_toolkit/reducers/modals/modalsSlice";


export const PartnerForm = ({
  editablePartner,
}) => {
  const dispatch = useDispatch();
  const isEditMode = !!editablePartner;

  const onSubmit = (values) => {
    isEditMode ? dispatch(editPartner(values)) : dispatch(addPartner(values));

    dispatch(hideModal());
  };

  return (
    <S.PartnerFormContainer>
      <DialogTitle>{isEditMode ? "Edit" : "Add new"} partner</DialogTitle>
      <Formik
        onSubmit={onSubmit}
        validationSchema={isEditMode ? validationSchemaUpdate : validationSchema}
        initialValues={editablePartner || initialValues}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {({
          getFieldProps,
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
        }) => {
          return (
            <S.PartnerFormWrapper onSubmit={handleSubmit}>
              <TextField
                {...getFieldProps("name")}
                value={values.name}
                fullWidth
                id="name"
                name="name"
                label="Name"
                onChange={(e) => {
                  handleBlur(e);
                  handleChange(e);
                }}
                variant="outlined"
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                size="small"
              />
              <S.ButtonsContainer>
                <Button color="secondary" onClick={()=> dispatch(hideModal())}>
                  Close
                </Button>
                <Button onClick={handleSubmit} type="submit">
                  {isEditMode ? "Edit" : "Create"}
                </Button>
              </S.ButtonsContainer>
            </S.PartnerFormWrapper>
          );
        }}
      </Formik>
    </S.PartnerFormContainer>
  );
};
