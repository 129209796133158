import styled from "styled-components/macro";

export const PartnerInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content:space-between;

  &.sidebarClosed {
    gap: 0px;
    flex-direction: row;
    padding: 0px 0px 0px 0px;
    margin-left: 0;
    flex-direction:column !important;
  }
`;

export const PartnerMainInfo = styled.div`
  display: flex;
  align-items: center;
  &.sidebarClosed{
    flex-direction:column;
  }
`;

export const PartnerAvatar = styled.div`
  display: flex;
  width: 51px;
  height: 51px;
  background-size: cover;
  position: relative;
  &.sidebarClosed {
    width: 40px;
    height: 31px;
    margin-bottom:15px;
  }
`;

export const StatusSelectWrapper = styled.div`
  &.sidebarClosed{
    left: -30px;
    > div {
      > div {
        display: flex;
        flex-direction: column;
        left: 3px;
        flex-direction: row-reverse;
      }
    }
  }

  .MuiInputBase-root {
    margin-left: 0px;
  }

  .MuiSelect-select {
    padding-right: 20px;
  }

  .MuiSelect-icon {
    color: #fff;
    top: 6px;
    right: 6px;
  }

  @media (max-width:950px){
    left: -30px;
    > div {
      > div {
        display: flex;
        flex-direction: column;
        flex-direction: row-reverse;
        left: 3px;
      }
    }
  }
`;

export const ImgAvatar = styled.div`
  display: flex;
  width: 49px;
  height: 49px;
  border-radius: 50%;
  background-size: contain;
  position: relative;
  background-position: center;
  &.sidebarClosed {
    width: 30px;
    height: 30px;
    margin-bottom:15px;
  }
`;

export const PartnerName = styled.div`
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin-left: 11px;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  overflow-wrap: break-word;
  // word-break: break-all;
  &.sidebarClosed {
    display: block;
    margin-left: 0;
  }
  @media only screen and (max-width: 1920px) and (min-width: 951px) {
    &.sidebarClosed {
      font-size: 12px;
    }
  }
  @media (max-width:950px){
    max-width: 90px;
    &.sidebarClosed {
      font-size: 12px;
      margin-left:unset;
    }
  }
`;

export const selectStyles = {
  indicatorContainerStyles: {
    '& div[class*="indicatorContainer"]': { padding: '0' },
  },
  valueContainerStyles: {
    padding: 0,
    display: 'flex',
  },
  controlStyles: {
    width: 45,
    cursor: 'pointer'
  }
}