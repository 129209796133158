import * as S from './ChatHeader.style';
import { memo, useCallback, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Remove, Close } from '@mui/icons-material';
import { Button } from 'ui';
import { viewChatEnd, endThisChat } from "socket/socket";
import { removeWatchingChat } from "redux_toolkit/reducers/chat/chatSlice";
import { hideModal, showModal } from "redux_toolkit/reducers/modals/modalsSlice";
import { updateChat } from 'redux_toolkit/reducers/chat/chatSlice';


export const ChatHeader = memo(({canEdit, chatData})=> {
  const dispatch = useDispatch();

  const chatWindowCallback = useCallback((e) =>{
    if (canEdit) {
      if((e.key === ',' && e.ctrlKey) || e.key === 'ESC') {
        e.preventDefault();
        dispatch(hideModal());
      } else if(e.key === '.' && e.ctrlKey){
        dispatch(showModal(
          {
            type: 'confirmation',
            title: `Are you sure you want to end chat with "${chatData.companion.name}" ?`,
            hideModal: hideModal,
            confirmText: 'End Chat',
            onConfirm: () => {
              endThisChat({chatId: chatData._id, companionUserId: chatData.companion._id, agent: chatData.user.name, createdAt: chatData.messages[4]});
            },
            closeAllModals: true,
          }
        ))}
      }
    },
  [ chatData.companion.name,
    canEdit,
    chatData._id,
    chatData.companion._id,
    chatData.user.name,
    chatData.messages,
    dispatch
  ]);

  useEffect(() => {
    window.removeEventListener('keydown', chatWindowCallback)
    window.addEventListener('keydown', chatWindowCallback)
    return () => {
      window.removeEventListener('keydown', chatWindowCallback)
    }
  },[chatWindowCallback]);

  return(
    <S.ChatHeader>
      <S.ClientName>
        {chatData.companion.name}
      </S.ClientName>
      <S.ClientName>
        {chatData.widgetName}
      </S.ClientName>
      <S.ContainerBtn>
        <Button onClick={()=> {
              dispatch(hideModal());
              dispatch(updateChat({chatId: chatData._id, data: {chatOpen: false}}));
            }
          }
        >
          <Remove />
        </Button>
        <Button
          onClick={() => {
            if(canEdit){
              dispatch(showModal(
              {
                type: 'confirmation',
                title: `Are you sure you want to end chat with "${chatData.companion.name}" ?`,
                hideModal: hideModal,
                confirmText: 'End Chat',
                onConfirm: () => {
                  endThisChat({chatId: chatData._id, companionUserId: chatData.companion._id, agent: chatData.user.name, createdAt: chatData.messages[4]});
                },
                closeAllModals: true,
              }))
            }
            else{
              viewChatEnd(chatData._id);
              dispatch(removeWatchingChat({chatId: chatData._id}));
              dispatch(hideModal());
            }
          }
        }
        >
          <Close />
        </Button>
      </S.ContainerBtn>
    </S.ChatHeader>
  )
}, (prevProps, nextProps)=> {
  return(
    prevProps.canEdit === nextProps.canEdit &&
    prevProps.chatData === nextProps.chatData
  )
})