import * as S from './RadioApperance.style';
import { FormControlLabel, RadioGroup } from "@material-ui/core"
import { getDefaultId } from 'utils/functions';

export const RadioApperance = ({
  value,
  items,
  onChange
})=> {

  return(
    <RadioGroup
      row
      aria-label="position"
      name="position"
      value={value}
      onChange={onChange}
    >
      {
        items.map((item)=> {
          return(
            <FormControlLabel
              key={getDefaultId()}
              value={item.value}
              control={<S.RadioMI color="primary" />}
              label={item.label}
            />
          )
        })
      }
    </RadioGroup>
  )
}