import styled from "styled-components/macro";
import MaterialButton from "@material-ui/core/Button";
import MaterialDivider from "@material-ui/core/Divider";

export const SidebarHeader = styled.div`
  display: flex;
  gap: 8px;
  transition: width 0.3s ease;
  background: #1b1b1b;
  flex-direction: column;
  padding: 15px 10px 0px 10px;

  &.sidebarClosed {
    padding: 15px 5px 0px 5px;
  }
`;

export const PartnerAllInfo=styled.div`
  display:flex;
  justify-content:space-between;
  &.sidebarClosed{
    flex-direction: column;
  }
`;

export const Button = styled(MaterialButton)`
  color: #fff;
  min-width:unset;
 
  .MuiButton-label {
    justify-content:unset;
    width:unset;
  }
  svg {
    width: 20px !important;
    height: 20px !important;
  }
  @media (max-width:1024px){
    max-width:unset;
    margin-left: 0px;

    .MuiButton-label {
     width:unset;
    }
    &.sidebarClosed {
      margin-left: unset;
    }
  }
  @media (min-width:1400px){
    svg{
      width: 25px !important;
      height: 25px !important;
    }
  }
  @media (max-width:950px){
    display: none
  }
`;

export const Divider = styled(MaterialDivider)`
  background: rgba(180, 180, 180, 0.3);
  margin: 20px 0px;
`;
