import {
ChoosePartnerPage,
LoginPage,
} from "pages"
import { Navigate, Route, Routes } from "react-router-dom"
import {
  LayoutUsers,
  LayoutNotAuth,
  LayoutPartners,
  LayoutSuperAdmin
} from "../layouts";
import { authRoots, notAuthRoots, superadminRoots } from "./Routes.config";
import { getDefaultId } from "utils/functions";


export const Pages = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<LayoutNotAuth />}>
        <Route index element={<Navigate to="login" />} />
        { notAuthRoots.map((item)=> <Route key={getDefaultId()} path={item.path} element={item.element} />) }
        <Route path="*" element={<Navigate to="login" />} />
      </Route>

      {/* Partner Selection Route */}
      <Route path="partners" element={<LayoutPartners />}>
        <Route index element={<ChoosePartnerPage />} />
        <Route path="*" element={<Navigate to="partners" />} />
      </Route>

      {/* SuperAdmin Login */}
      <Route path="superadmin/login" element={<LayoutNotAuth />}>
        <Route index element={<LoginPage />} />
        <Route path="*" element={<Navigate to="/superadmin/login" />} />
      </Route>

      {/* Authenticated Super Admin Routes */}
      <Route path="superadmin" element={<LayoutSuperAdmin />}>
        <Route index element={<Navigate to="home" />} />
        { superadminRoots.map((item)=> <Route key={getDefaultId()} path={item.path} element={item.element} />) }
        <Route path="*" element={<Navigate to="home" />} />
      </Route>

      {/* Authenticated User Routes */}
      <Route path=":partnerKey" element={<LayoutUsers />}>
        <Route index element={<Navigate to="home" />} />
        { authRoots.map((item)=> <Route key={getDefaultId()} path={item.path} element={item.element} />) }
        <Route path="*" element={<Navigate to="home" />} />
      </Route>
    </Routes>
  );
};