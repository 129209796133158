import styled from 'styled-components/macro'
import MaterialAppBar from '@material-ui/core/AppBar';
import MaterialTypography from '@material-ui/core/Typography';
import MaterialButton from '@material-ui/core/Button';
import MaterialToolbar from '@material-ui/core/Toolbar';

export const AppBar = styled(MaterialAppBar)`
  box-shadow: none;
  background: unset;
`;

export const Toolbar = styled(MaterialToolbar)`
  display: flex;
  justify-content: space-between;
  @media (max-width:500px){
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export const Typography = styled(MaterialTypography)`
  color: #555555;
  font-size: 1rem;
  @media (min-width:1600px){
    font-size:1.5rem;
  }
`;

export const ButtonsList = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width:950px){
    gap: 5px;
  }
`;

export const Button = styled(MaterialButton)`
   font-size: 1rem;

  .MuiButton-label {
    display: flex;
    color: #555;
    justify-content: center;
    align-items: center;
    img {
      margin-left: 8px;
    }
  }
  @media (min-width:1600px){
    .MuiButton-label {
      font-size:1.3rem;
    }
  }
  @media (max-width:950px){
    min-width: 50px;

    svg{
     width: 16px!important;
     height: 16px!important;
    }
  }

`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #bdbdbd;
  display: flex;
  justify-content: center;
  align-items: center;
  position:relative;
`;

export const AvatarImage=styled.img`
  width:100%;
  height:100%;
  border-radius: 50%;
  position:absolute;
  margin-left:unset!important;
`;

export const UsernameLetter = styled.div`
  color: #fff;
  font-size: 20px;
  margin-top: 5px;
  
`;