import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router"
import { Modal, AuthLayout, Notification } from "components";
import { loginSuperAdmin } from "redux_toolkit/reducers/app/appSlice";


export const LayoutSuperAdmin = ()=> {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userInfo } = useSelector(({ user }) => user);

  const initAppCallback = useCallback(async() => {
    dispatch(loginSuperAdmin());
  }, [dispatch]);

  useEffect(()=> {
    initAppCallback();
  }, [initAppCallback]);

  const accessToken = localStorage.getItem('superadmin_token');

  useEffect(()=> {
    if(!!!accessToken ) {
      navigate(`/superadmin/login`);
    }
  }, [accessToken, navigate])

  return(
    <>
      <Modal />
      <Notification />
      {!!accessToken && userInfo && (
        <AuthLayout isSidebarOpened={+localStorage.getItem('sidebar_open')}>
          <Outlet />
        </AuthLayout>
      )}
    </>
  )
}