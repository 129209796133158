import styled from 'styled-components/macro'

export const HistoryPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-start;

  button {
    min-width: unset !important;
    font-size: 13px !important;
    padding: 8px 15px !important;
  }
  button:nth-child(2){
    border:1px solid;
  }
`;