import styled from 'styled-components/macro';
import MaterialButton from '@material-ui/core/Button';
import MaterialPopover from '@material-ui/core/Popover';

export const ContentContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background: #eee;
  min-height: 100vh;
`;

export const AuthLayout = styled.div`
  display: flex;
  height: 100%;
`;

export const MainContainer = styled.div`
  padding: 24px;
`;

export const ChatsButton = styled(MaterialButton)`
  z-index: 1200;
  position: fixed;
  min-width: 0px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  bottom: 10px;
  &.sidebarClosed {
    left: 120px;
  }
  .MuiSvgIcon-root {
    width: 32px;
    height: 32px;
  }
  @media (max-width:950px){
    width: 50px;
    height: 50px;
  }
`;

export const ChatsListPopover = styled(MaterialPopover)`
  .MuiPaper-root {
    padding: 18px !important;
    &:before {
      right: 100%;
      bottom: 0;
      content: "";
      position: absolute;
      border-top: 12px solid transparent;
      border-right: 12px solid red;
    }
  }
`;