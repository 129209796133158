import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import { RadioGroup, FormControlLabel } from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/components/radioButtonStyle.js";
import { RadioBox } from "ui";

const useStyles = makeStyles(styles);

export function RadioButtons(props) {
  const classes = useStyles();
  const {
    color,
    data,
    disabled,
    size,
    className,
    muiClasses,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.radio]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.disabled]: disabled,
    [className]: className,
  });
  return (
    <RadioGroup {...rest} classes={muiClasses} className={btnClasses}>
     	{data.map((val, index) => (
				<FormControlLabel
          key={index}
          value={val.id}
          label={val.label}
          control={<RadioBox color="primary" />}
          style={{marginRight: '20px', marginLeft: '5px'}}
					/>))}
    </RadioGroup>
  );
}

RadioButtons.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "transparent",
    'secondary'
  ]),
  size: PropTypes.oneOf(["sm", "lg"]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  // use this to pass the classes props from Material-UI
  muiClasses: PropTypes.object,
  data: PropTypes.array,
};
