import * as S from "./AuthLayout.styles";
import React, { memo } from "react";
import { Sidebar, Shortcuts } from "components";
import { LayoutContainer } from "./LayoutContainer/LayoutContainer";


export const AuthLayout = memo(({ children }) => {
  return (
    <S.AuthLayout>
      <Shortcuts />
      <Sidebar />
      <S.ContentContainer>
        <LayoutContainer />
        <S.MainContainer>{children}</S.MainContainer>
      </S.ContentContainer>
    </S.AuthLayout>
  );
}, (prevProps, nextProps)=> {
  return prevProps.children === nextProps.children
});
