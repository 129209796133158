import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";
import { NotAuthFormLayout, NotAuthLayout } from "components";


export const LayoutNotAuth = ()=> {
  const navigate = useNavigate();

  useEffect(()=> {
    if(localStorage.getItem('superadmin_token')) {
      navigate(`/superadmin/home`);
      return;
    }

    if(localStorage.getItem('access_token')) {
      navigate(`/partners`);
    }
  }, [navigate])

  return (
    <NotAuthLayout>
      <NotAuthFormLayout>
        <Outlet />
      </NotAuthFormLayout>
    </NotAuthLayout>
  )
}