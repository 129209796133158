import * as S from './PersonalPage.styles'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TabPanel } from 'components'
import { ProfileTab } from './ProfileTab/ProfileTab'
import { NotificationsTab } from './NotificationsTab/NotificationsTab'
import { ROLES } from 'configs/constants'
import { notificationTabOpen } from 'redux_toolkit/reducers/notification/notificationSlice'


export const PersonalPage = () => {
  const dispatch = useDispatch();
  const { role } = useSelector(({ userPartner }) => userPartner.userData);
  const { notificationTab } = useSelector(({ notification }) => notification);
  const [activeTab, setActiveTab] = useState(+notificationTab || 0);

  return (
      <S.Paper>
        <S.Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, tab) => {
            setActiveTab(tab);
            dispatch(notificationTabOpen(Boolean(tab)));
          }}
          centered
          aria-label="personal tabs"
        >
          <S.Tab label="Profile" data-is-active={activeTab === 0}/>

      { role !== ROLES.ADMIN &&   <S.Tab label="Notifications" data-is-active={activeTab === 1}/> } 
        </S.Tabs>
        <TabPanel index={0} value={activeTab}>
          <ProfileTab />
        </TabPanel>
       { role!== ROLES.ADMIN && 
       <TabPanel index={1} value={activeTab}>
          <NotificationsTab />
        </TabPanel>
        }
      </S.Paper>
  )
}