import styled from 'styled-components/macro'
import {
  MenuItem as MuiMenuItem,
  TextareaAutosize,
} from '@material-ui/core'

export const ChatContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  background: #f0f0f0;
  padding: 10px 20px 20px;

  .ck-content {
    height: 81px;
  }

  .ck-editor {
    flex: 1;
    width: 90%;
    min-height: 120px;
  }

  .MuiButtonBase-root {
    border-radius: 50%;
    min-width: unset;

    .MuiSvgIcon-root {
      color: rgb(15, 61, 86);
    }
  }
`;

export const EditorContainer = styled.div`
  display: flex;
  border: 1px solid #dcdcdc;
  background: #fff;
  border-radius: 4px;
  position: relative;
`

export const EditorButtonsContainer= styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  margin: 0px 15px;
`;

export const FileInput = styled.input`
  display: none;
`;

export const InputLabel = styled.label`
  cursor: pointer;
`;

export const ShortcutSelect = styled.div`
  position: absolute;
  width: 100%;
  top: 27px;
  padding-right: 70px;
  @media (max-width: 970px) {
    padding-right: 80px;
  }
`;

export const ShortcutContainer = styled.div`
  position: relative;
  z-index: 3;
  width: 100%;
  height: 173px;
  background: #393939;
  overflow: auto;
`;

export const ContainerBtn = styled.div`
  display: flex;
  justify-content: end;
`;

export const FileContent = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  svg {
    cursor: pointer;
    position: absolute;
    right: -5px;
    top: -5px;
    fill: white;
    background: #0f3d56;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 3px;
    z-index: 5;
  }
`;

export const SelectedImage = styled.img`
  width: 80px;
  height: 80px;
`;

export const SelectedVideo = styled.video`
  width: 80px;
  height: 80px;
`;

export const TextArea = styled(TextareaAutosize)`
  resize: none;
  padding: 10px;
  border: 0;
  border-right: 1px solid #dcdcdc;
  width: 100%;
`;

export const MenuItem = styled(MuiMenuItem)`
  cursor: pointer;
  width: 100%;
  border-radius: 0 !important;
  color: #FFFFFF;
  &.isSelected {
    background: #0f3d56;
  }
`;