import styled from "styled-components/macro";
import MaterialButton from '@material-ui/core/Button';

export const HeaderContainerActions = styled.div`
  color:#fff;
  font-size: 17px!important;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiSwitch-colorPrimary.Mui-checked{
    color: #444!important;
  }
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track{
    background-color:#999!important;
    opacity: 1!important;
  }
  @media (max-width:950px){
    font-size: 8px!important;
  }
`;

export const Plus = styled(MaterialButton)`
  color: #fff;
  .fa-plus{
    font-size: 15px!important;
  }
  @media (max-width:950px){
    .fa-plus{
      font-size: 12px!important;
    }
  }
`;