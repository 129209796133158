import React, { useCallback, useEffect } from 'react';
import { Outlet, useNavigate } from "react-router";
import { useDispatch } from 'react-redux';
import { NotAuthFormLayout, NotAuthLayout } from 'components';
import { loginStepOne } from 'redux_toolkit/reducers/app/appSlice';


export const LayoutPartners = ()=> {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initAppCallback = useCallback(() => {
    dispatch(loginStepOne());
  }, [ dispatch ]);

  useEffect(()=> {
    initAppCallback();
  }, [initAppCallback])

  useEffect(()=> {
    if(localStorage.getItem('superadmin_token')) {
      navigate("/superadmin/home");
      return;
    }

    if(!!!localStorage.getItem('access_token')) {
      navigate('/login')
    }
  }, [navigate]);

  return (
    <>
      {<NotAuthLayout>
        <NotAuthFormLayout headerHeight={125}>
          <Outlet />
        </NotAuthFormLayout>
      </NotAuthLayout>}
    </>
  )
}