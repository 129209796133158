import { createSlice } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';

enableMapSet();

const initialState = {
  chatRequests: new Set(),
  chats: new Map(),
  watchingChats: new Map(),
  loading: false,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setNewChat: (state, action)=> {
      const chat = action.payload.chat;
      let oldMessages = [];

      const data = [...state.chats.values()].find(item => item.companion._id === chat.companion._id);
      if(data) {
        state.chats.delete(data._id);
        oldMessages = data.messages;
      }

      state.chats = state.chats.set(
        chat._id,
        {...chat, messages: [...oldMessages, ...action.payload.messages[0]], unreadCount: action.payload.unreadCount, chatOpen: data?.chatOpen}
      );
    },
    setNewMessage: (state, action)=> {
      const chatId = action.payload.chatId;
      const chatHistory = state.chats.get(chatId);
      const watchingChatHistory = state.watchingChats.get(chatId);

      if(chatHistory){
        state.chats = state.chats.set(chatId, {
          ...chatHistory,
          unreadCount: chatHistory.chatOpen? 0 : ++chatHistory.unreadCount,
          messages: [...(chatHistory.messages || []), ...action.payload.message]
        })
      }

      if(watchingChatHistory) {
        state.watchingChats = state.watchingChats.set(chatId, {
          ...watchingChatHistory,
          messages: [...(watchingChatHistory.messages || []), ...action.payload.message]
        })
      }
    },
    addHistory: (state, action)=> {
      const chatId = action.payload.chatId;
      const myPrevChatHistory = state.chats.get(chatId);
      if(myPrevChatHistory) {
        state.chats = state.chats.set(
          chatId,
          { ...(myPrevChatHistory || []), messages: [...action.payload.messages, ...(myPrevChatHistory?.messages || [])] }
        );
        return;
      }

      const prevChatHistory = state.watchingChats.get(chatId);
      if(prevChatHistory) {
        state.watchingChats = state.watchingChats.set(
          chatId,
          { ...(prevChatHistory || []), messages: [...action.payload.messages, ...(prevChatHistory?.messages || [])] }
        );
      }
    },
    endChatState: (state, action)=> {
      const chatId = action.payload.chatId;
      const myPrevChatHistory = state.chats.get(chatId);
      if(myPrevChatHistory) {
        state.chats = state.chats.set(chatId, { ...(myPrevChatHistory), chatEnd: true});
      }
    },
    deleteChat: (state, action)=> {
      state.chats.delete(action.payload.chatId);
    },
    incomingChats: (state, action) => {
      for(let item of action.payload){
        state.chatRequests.add(item);
      }
    },
    deleteChatRequest: (state, action)=> {
      state.chatRequests.delete(action.payload.chatId);
    },
    updateChat: (state, action)=> {
      const chatHistory = state.chats.get(action.payload.chatId);
      if (chatHistory) {
        state.chats = state.chats.set(action.payload.chatId, {...chatHistory, ...action.payload.data});
      }
    },
    removeChat: (state, action)=> {
      const agentChats = state.chats;
      agentChats.delete(action.payload.chatId);
      state.chats = agentChats;
    },
    addWatchingChat: (state, action)=> {
      state.watchingChats = state.watchingChats.set(
        action.payload.chat._id,
        {...action.payload.chat, messages: action.payload.messages[0] || [], unreadCount: action.payload.unreadCount || 0, chatOpen: false});
    },
    removeWatchingChat: (state, action)=> {
      state.watchingChats.delete(action.payload.chatId);
    },
    updateWatchingChat: (state, action)=> {
      const {chatId, user} = action.payload;
      const watchingChat = state.watchingChats.get(chatId);
      state.watchingChats = state.watchingChats.set(chatId, {...watchingChat, user});
    }
  }
});

export const {
  endChatState, 
  deleteChat,
  addHistory, 
  setNewMessage, 
  setNewChat, 
  addReferrer,
  incomingChats,
  deleteChatRequest,
  updateChat,
  removeChat,
  addWatchingChat,
  removeWatchingChat,
  updateWatchingChat,
} = chatSlice.actions;
export const selectChat = (state) => state.chat;
export default chatSlice.reducer;
