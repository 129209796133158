import * as S from "./ProfileTab.styles";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ClearIcon from "@mui/icons-material/Clear";
import { ProfileForm } from "./ProfileForm/ProfileForm";
import { EditProfile } from "components";
import { Button } from "@material-ui/core";
import {
  uploadDashboardImage,
  uploadProfileImage,
  deleteDashboardImage,
  deleteProfileImage
} from "redux_toolkit/reducers/user_partner/userPartnerSlice";
import { ROLES } from "configs/constants";


export const ProfileTab = () => {
  const dispatch = useDispatch()
  const userInfo = useSelector(({ userPartner }) => userPartner.userData);
  const userData = {
    _id: userInfo.userId,
    name: userInfo?.name,
    email: userInfo?.email,
    tagLine: userInfo?.tagLine,
    isAdmin:userInfo.role===ROLES.ADMIN,
    dashboardImage:userInfo?.dashboardImage,
    profileImage:userInfo?.profileImage,
  }

  const [modalOpened, setModalOpened] = useState(false);
  const [dashboardImage, setDashboardImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [showDeleteProfile, setShowDeleteProfile] = useState(false);
  const [showDeleteDashboard, setShowDeleteDashboard] = useState(false);

  const handleFileUploadDashboard = (event) => {
    setDashboardImage(event.target.files[0]);
  };
  const handleFileUploadProfile = (event) => {
    setProfileImage(event.target.files[0]);
  };

  const ref = useRef();
  const ref2 = useRef();
  return (
    <S.ProfileTabContainer>
      <S.ProfileTabContent>
        <S.TabTitleContainer>
          <S.TabTitle>Profile</S.TabTitle>
          <div>
            <input
              ref={ref}
              onChange={handleFileUploadProfile}
              onClick={handleFileUploadProfile}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              multiple
            />
            <S.ImgContainer>
              {userData.profileImage || profileImage ? (
                <div
                  onMouseEnter={() => setShowDeleteProfile(true)}
                  onMouseLeave={() => setShowDeleteProfile(false)}
                >
                  <S.Image
                    src={userData.profileImage  ? userData.profileImage : URL.createObjectURL(profileImage) }
                    alt="profile_logo1"
                    style={{ width: "100%" }}
                  />
                  {showDeleteProfile && (
                    <S.Delete
                      onClick={() =>{
                        dispatch(deleteProfileImage({ profileImage: true, _id: userInfo._id, partnerId: userInfo.partnerId }))
                        setProfileImage(null)
                      }
                      }
                    >
                      <ClearIcon />
                    </S.Delete>
                  )}
                </div>
                 
              ) : (
                <S.AddPictureIcon onClick={() => ref.current.click()}>
                  <AddPhotoAlternateIcon />
                </S.AddPictureIcon>
              )}
            </S.ImgContainer>
          </div>
          {(profileImage && !userData.profileImage) && (
            <S.UploadBtn onClick={() => dispatch(uploadProfileImage({partnerId: userInfo.partnerId, profileImage}))}>
              Upload
            </S.UploadBtn>
          )}
        </S.TabTitleContainer>
        <S.FormContainer>
          <ProfileForm userData={userData} />
        </S.FormContainer>
        {userData.isAdmin && (
          <S.TabTitleContainer>
            <S.DashboardTitle>Dashboard logo</S.DashboardTitle>
            <div>
              <input
                ref={ref2}
                onChange={handleFileUploadDashboard}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                multiple
              />
              <S.ImgContainer>
              {userData.dashboardImage || dashboardImage ? (
                <div
                  onMouseEnter={() => setShowDeleteDashboard(true)}
                  onMouseLeave={() => setShowDeleteDashboard(false)}
                >
                  <S.Image
                    src={userData.dashboardImage  ? userData.dashboardImage : URL.createObjectURL(dashboardImage) }
                    alt="profile_logo1"
                    style={{ width: "100%" }}
                  />
                  {showDeleteDashboard && (
                    <S.Delete
                      onClick={() =>{
                        dispatch(deleteDashboardImage({ dashboardImage: true, _id: userInfo._id, partnerId: userInfo.partnerId}));
                        setDashboardImage(null);
                      }
                      }
                    >
                      <ClearIcon />
                    </S.Delete>
                  )}
                </div>
                 
              ) : (
                <S.AddPictureIcon onClick={() => ref2.current.click()}>
                  <AddPhotoAlternateIcon />
                </S.AddPictureIcon>
              )}
              </S.ImgContainer>
            </div>
            {(dashboardImage &&  !userData.dashboardImage) && (
              <S.UploadBtn onClick={() => dispatch(uploadDashboardImage({partnerId: userInfo.partnerId, dashboardImage}))}>
                Upload
              </S.UploadBtn>
            )}
          </S.TabTitleContainer>
        )}
        <S.ButtonsContainer>
          <Button
            type="submit" 
            onClick={() => setModalOpened(true)}
            variant="contained"
            color="primary"
            size="small"
            >
            Edit
          </Button>
        </S.ButtonsContainer>
      </S.ProfileTabContent>
      {modalOpened && (
        <EditProfile
          userData={userData}
          modalOpened
          setModalOpened={setModalOpened}
        />
      )}
    </S.ProfileTabContainer>
  );
};
