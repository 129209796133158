import * as S from './BannedPage.styles'
import React , { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import { Table } from 'components'
import { Button, SearchInput } from 'ui';
import { tableHead, formatTableData } from './BannedPage.config'
import { visitorBan } from 'socket/socket';
import { getBannedVisitors } from 'redux_toolkit/reducers/partner/visitors/visitorsSlice';


export const BannedPage = ()=> {
  const dispatch = useDispatch()
  const bannedList = useSelector(({ visitors }) => visitors.bannedVisitors);
  const { partnerId } = useSelector(({ userPartner }) => userPartner.userData);
  const [page, setPage] = useState(1);
  const [search,setVal] = useState('');

  const list = useMemo(() => {
    if (!search) {
      return bannedList;
    }
    const regex = new RegExp(search);
    return bannedList.filter(item => regex.test(item.name));

  }, [bannedList, search]);

  useEffect(()=>{
    dispatch(getBannedVisitors(partnerId));
  }, [partnerId, dispatch])

  const handleChange = (_e, p) => {
    setPage(p);
  };

  const actionButton = ({ _id }) => {
    return (
      <Button onClick={()=> {
        visitorBan(_id, 0);
        }
      }>
        Unban
      </Button>
    )
  }

  return (
    <S.BannedPageContainer>
      <S.SearchContainer id='tableTop'>
        <SearchInput placeholder='Search...' 
          value={search}
          onChange={(e)=>setVal(e.target.value)}
          clearInput={() => setVal('')}
        />
        <Pagination
          count={bannedList > 0 ? Math.floor(bannedList / 20) : 1}
          size="small"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </S.SearchContainer>
      <S.TableContainer>
        <Table
          tableHead={tableHead}
          tableData={formatTableData(list, actionButton)}
          allScreen={true}
          notFound={list.length > 0? '':'Not Banned Visitors'}
        />
      </S.TableContainer>
    </S.BannedPageContainer>
  )
}