import * as Yup from 'yup';

export const initialValues = {
  password: '',
  confirmPassword: ''
};

const requiredErrorMessage = 'This field is required';

export const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'The password length must be greater than or equal to 8')
    .required(requiredErrorMessage),
  confirmPassword: Yup.string()
  .oneOf([Yup.ref('password'), null], 'Passwords did not match')
});
