import cx from 'classnames'
import * as S from "./ViewChats.style";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { viewChat } from 'socket/socket';
import { showModal } from 'redux_toolkit/reducers/modals/modalsSlice';


export const ViewChats = memo(({ currentUser, isSidebarOpened })=> {
  const dispatch = useDispatch();
  const { partnerTeam } = useSelector(({ agents })=> agents);
  const { watchingChats } = useSelector(({ chat }) => chat);

  const [listViewChats, setListViewChats] = useState([]);
  const [openedMenu, setOpenedMenu] = useState({});

  const teamList = useMemo(()=> [...partnerTeam.values()] ,[partnerTeam]);

  const listDataFunc = useCallback(() => {
    const eyeData = [];
    for(let agent of teamList) {
      let agentChats = {
        agentName: agent.name,
        agentId: agent._id,
        chats: []
      };
      for(let watchingChat of [...watchingChats.values()]){
        if (agent._id === watchingChat.user._id) agentChats.chats.push(watchingChat);
      }
      if(agentChats.chats.length && currentUser._id !== agent._id) eyeData.push(agentChats);
    }
    return eyeData;
  }, [watchingChats, currentUser._id, teamList]);

  const handleWatchChat = useCallback((chat, agent) => {
    viewChat(chat._id);
    dispatch(showModal({
      type: 'clientChat',
      canEdit: false,
      watchingAgentId: currentUser._id,
      agentName: agent.agentName,
      chatId: chat._id
    }));
  }, [currentUser._id, dispatch]);

  useEffect(()=> {
    setListViewChats(listDataFunc());
  },[listDataFunc])

  return(
    <>
      {listViewChats.length ?
        <>
          {listViewChats.map((item, index) => (
            <S.ChatsContent key={index}>
              <S.AgentName onClick={() => setOpenedMenu({ [index]: !openedMenu[index] })}>
                {item.agentName}
                <KeyboardArrowDownIcon className={cx({ isOpened: openedMenu[index] })} />
              </S.AgentName>
              { openedMenu[index] &&
                <S.ChatsContainer in={openedMenu[index]} unmountOnExit>
                  {item.chats.map((chat) => {
                    return (
                      <S.ListItem
                        onClick={() => handleWatchChat(chat, item)}
                        disableGutters={true}
                        key={chat._id}
                        className={`${cx({ sidebarClosed: !isSidebarOpened })}`}
                      >
                        <S.ListItemText className="agent_name">#{chat.companion.name}</S.ListItemText>
                      </S.ListItem>
                    );
                  })}
                </S.ChatsContainer>
              }
            </S.ChatsContent>
          ))}
        </>
      :
        <S.EmptyChatsListText>No chats found</S.EmptyChatsListText>
      }
    </>
  )
}, (prevProps, nextProps)=> {
  return(
    prevProps.currentUser === nextProps.currentUser &&
    prevProps.isSidebarOpened === nextProps.isSidebarOpened 
  );
});