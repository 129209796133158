import * as S from './ShortcutsForm.styles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Formik } from 'formik'
import { validationSchema, initialValues } from './ShortcutsForm.config'
import { Button } from 'ui'
import { addShortcut, updateShortcut } from 'redux_toolkit/reducers/partner/shortcut/shortcutSlice'
import { hideModal } from 'redux_toolkit/reducers/modals/modalsSlice'
import { compact } from 'utils/functions'


export const ShortcutsForm = ({
  editableShortcut
}) => {
  const dispatch = useDispatch();
  const shortcutsList = useSelector(({ shortcut }) => shortcut.list);
  const { partnerId } = useSelector(({ userPartner }) => userPartner.userData);

  const isEditMode = !!editableShortcut

  const onSubmit = (values) => {
    const options = [];
    values.options.forEach((option) => options.push(option.trim()));
    const shortcut = { message: values.message.trim(), tag: values.tag.trim(), options: compact(options)};
    const shortcutId = values._id;
    isEditMode
      ? dispatch(updateShortcut({partnerId, shortcutId, shortcut}))
      : dispatch(addShortcut({partnerId, shortcut}));

    dispatch(hideModal());
  }

  const validate = (values) => {
    const errors = {};
    if (!values.message.trim()) {
      errors.message = 'This field is required'
    }
    if (!values.tag.trim()) {
      errors.tag = 'This field is required';
    } else if (shortcutsList.find(s => s.tag === values.tag.trim() && values.tag.trim() !== editableShortcut?.tag)) {
      errors.tag = 'this tag already exists'
    }
    return errors;
  };

  return (
    <S.ShortcutsFormContainer>
      <DialogTitle>
        { isEditMode ? 'Edit' : 'Add' } Shortcut
      </DialogTitle>
      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={editableShortcut || initialValues}
        validateOnChange={true}
        validate={validate}
        validateOnBlur={false}
      >
        {
          ({
            dirty,
            values,
            isValid,
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            setFieldValue,
            getFieldProps
          }) => {
            return (
              <S.ShortcutsFormWrapper onSubmit={handleSubmit}>
                <TextField
                  {...getFieldProps('tag')}
                  value={values.tag}
                  fullWidth
                  id="tag"
                  size='small'
                  name='tag'
                  label="tag *"
                  error={!!errors.tag}
                  helperText={errors.tag}
                  onChange={(e) => {
                    handleBlur(e)
                    handleChange(e)
                  }}
                  variant="outlined"
                />
                <TextField
                  {...getFieldProps('message')}
                  value={values.message}
                  fullWidth
                  size='small'
                  id="message"
                  name='message'
                  label="Message *"
                  variant="outlined"
                  multiline={true}
                  minRows={12}
                  error={!!errors.message}
                  helperText={errors.message}
                  onChange={(e) => {
                    handleBlur(e)
                    handleChange(e)
                  }}
                />
                {
                  values.options.map((option, index) => {
                    return (
                      <S.OptionContainer key={index}>
                        <TextField
                          value={option}
                          size='small'
                          fullWidth
                          id="option"
                          name='option'
                          label="Option"
                          variant="outlined"
                          onChange={(e) => {
                            setFieldValue('options', values.options.map((o, i) => i === index ? e.target.value : o))
                          }}
                        />
                        {
                          values.options.length > 1 &&
                            <Button
                              color='secondary'
                              onClick={() => {
                                setFieldValue(
                                  'options',
                                  values.options.filter((_el, i) => i !== index)
                                )
                              }}
                            >
                              <RemoveIcon />
                            </Button>
                        }
                      </S.OptionContainer>
                    )
                  })
                }
                <Button
                  color='secondary'
                  onClick={() => {
                    setFieldValue('options', [...values.options, ''])
                  }}
                >
                  <AddIcon />
                </Button>
                <S.ButtonsContainer>
                  <Button
                    color='secondary'
                    onClick={()=> dispatch(hideModal())}
                  >
                    Close
                  </Button>
                  <Button
                    type='submit'
                    onClick={handleSubmit}
                    disabled={!(isValid && dirty)}
                  >
                    { isEditMode ? 'Edit' : 'Save' }
                  </Button>
                </S.ButtonsContainer>
              </S.ShortcutsFormWrapper>     
            )
          }
        }
      </Formik>
    </S.ShortcutsFormContainer>
  )
}