import * as S from './AgentsPage.styles';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Table, Loader, HeaderButtons } from 'components';
import { formatTableData, headData } from './AgentsPage.config';
import { ROLES, STATUS } from 'configs/constants';
import { logoutAgent, updateActivity } from 'socket/socket';
import { showModal } from 'redux_toolkit/reducers/modals/modalsSlice';
import {
  getAutoAssign,
  updateAgent,
  getAgentsByPartnerId,
} from 'redux_toolkit/reducers/partner/agents/agentsSlice';


export const AgentsPage = () => {
  const dispatch = useDispatch();
  const { partnerTeamleads, partnerAgents, loading, request } = useSelector(({ agents }) => agents);
  const currentUser = useSelector(({ userPartner }) => userPartner.userData);

  const userRole = currentUser?.role;
  const partnerId = currentUser.partnerId;
  const [tableHead, setTableHead] = useState(headData);

  const list = useMemo(()=> partnerAgents.concat(partnerTeamleads), [partnerTeamleads, partnerAgents]);

  useEffect(() => {
    dispatch(getAutoAssign(partnerId));
    dispatch(getAgentsByPartnerId(partnerId));
    if (userRole === ROLES.TEAMLEADER) {
      setTableHead(headData.filter(item => item !== "Activity"));
    }
  }, [userRole, partnerId, dispatch]);

  const nestedComponentsChange = (component, { id, email, value })=> {
    if (component === "Select") {
      dispatch(updateAgent({ agentId: id, partnerId: partnerId, data: { limit: value, email }}));
    } else {
      const status = value ? STATUS.OFFLINE : STATUS.INACTIVE;
      updateActivity(id, status);
    }
  }

  const getTableActions = (agent) => {
    return [
    <S.ActionsContainer>
      <S.ActionButton
        onClick={() =>
          dispatch(showModal({
            type: 'agentForm',
            editableAgent: agent,
            isEdit: true,
          }))
        } 
      >
        <EditIcon />
      </S.ActionButton>
      <S.ActionButton disabled={agent?.status === STATUS.OFFLINE || agent?.status ===  STATUS.INACTIVE} onClick={() => logoutAgent(agent?._id)}>
        <LogoutIcon className='logout-icon' />
      </S.ActionButton>
      {userRole !== ROLES.TEAMLEADER && (
        <S.ActionButton
          onClick={() =>
            dispatch(showModal({
              type: 'removeUserModal',
              agentId: agent._id,
            }))
          }
        >
          <FontAwesomeIcon icon={faTrash} />
        </S.ActionButton>
      )}
    </S.ActionsContainer>,
    ]
  };

  return (
    <S.AgentsPageContainer>
      <Table
        tableHead={tableHead}
        tableData={formatTableData(list, getTableActions, nestedComponentsChange, userRole)}
        cardHeaderText={'Agents'}
        headerComponent={ <HeaderButtons userRole={userRole} request={request} /> }
        allScreen={true}
        notFound={list.length > 0? '':'Not Agents'}
      />
      {loading && <Loader color="primary" size={100} />}
    </S.AgentsPageContainer>
  );
};