import cx from "classnames";
import * as S from "./Sidebar.styles";
import React, {
  useEffect,
  useState,
  useRef
} from "react";
import { useSelector } from "react-redux";
import { PROJECT_VERSION } from "configs/constants";
import { DeveloperPortal, MenuWithHeader } from "components";


export const Sidebar = () => {
  const { isSidebarOpened } = useSelector(({ app }) => app);
  const [openDevPortal, setOpenDevPortal] = useState(false);
  const [sidebarClosed, setSidebarClosed] = useState(window.innerWidth <= 950? true : !isSidebarOpened);
  const showDrawerToggleRef = useRef(false);

  useEffect(() => {
    if (window.innerWidth > 950) {
      setSidebarClosed(!isSidebarOpened);
    }
    else {
      setSidebarClosed(true);
    }

    let timeoutId;
    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
          if (window.innerWidth <= 950) {
            setSidebarClosed(true);
          } else {
            setSidebarClosed(!isSidebarOpened);
          }
      }, 100);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', handleResize);
    };
  }, [isSidebarOpened]);


  return (
    <S.Drawer
      anchor={showDrawerToggleRef.current ? "right" : "left"}
      open={sidebarClosed}
      className={cx({ sidebarClosed })}
    >
      <S.TopMenuContainer>
        <MenuWithHeader  sidebarClosed={sidebarClosed} />
      </S.TopMenuContainer>
      <S.SidebarFooter sidebarClosed={sidebarClosed}>
        <S.Version>{sidebarClosed ? "" : "version"} {PROJECT_VERSION}</S.Version>
        <S.DevPortal>
          <DeveloperPortal
            color="#D1D1D1"
            show={openDevPortal}
            sidebarClosed={sidebarClosed}
            onShow={() => setOpenDevPortal(true)}
            onHide={() => setOpenDevPortal(false)}
          />
        </S.DevPortal>
      </S.SidebarFooter>
    </S.Drawer>
  )
}
