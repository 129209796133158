import * as S from "./ChoosePartnerPage.styles";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "ui";
import { Loader } from "components";
import { PartnersContainer } from "./PartnersContainer/PartnersContainer";
import {
  logoutUser
} from "redux_toolkit/reducers/user/userSlice";


export const ChoosePartnerPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { partners, userInfo, loading } = useSelector(({ user }) => user);

  if (loading) return <Loader />;

  return (
    <>
      <S.AvatarIcon />
      {partners && userInfo && <PartnersContainer currentUser={userInfo} partners={partners} />}
      <Button variant="contained" color="primary" onClick={()=> {
        dispatch(logoutUser());
        navigate(`/login`);
      }}>
        Logout
      </Button>
    </>
  );
}
