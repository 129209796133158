import styled from 'styled-components/macro'
import { Button as ButtonUI } from "ui";

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
  width: 100%;
  position: relative;
  .MuiInputBase-root {
    font-size: 13px;
  }

  textarea {
    font-weight: bold;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  position: relative;
  gap: 20px;
  margin: 15px 0 5px;
`;

export const Button = styled(ButtonUI)`
  padding: 4px 8px !important;
  border-radius: 4px !important;
  min-width: auto !important;
`;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dcdcdc;
  min-width: 80px;
  height: 80px;
  border-radius: 4px;

  .MuiSvgIcon-root {
    font-size: 60px;
  }
`;

export const FormRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: 8px;
  gap: 8px;
  .MuiTextField-root {
    background: white;  
  }
`;

export const Referrers = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0,0,0,0.26);
  padding: 8px;
  overflow: auto;
  border-radius: 5px;
  max-height: 95px;
  min-height: 60px;
  background: white;

  span{
    margin: 5px 0px
  }
`;

export const Referrer = styled.div`
  font-weight: bold;
  font-size: 12px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .MuiTextField-root {
    background: white;  
  }
`;

export const ActivityContainer = styled.div`
  display: flex;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  background: white;
`;

export const ActivityItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 3px;
  font-weight: bold;
  flex: 1;
  border-right: 1px solid #dcdcdc;
  font-size: 17px;

  div:last-child {
    font-size: 12px;
  }

  &:last-child {
    border-right: none;
  }

  div {
    color: #555;
  }
`;

export const UserComputerData = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  gap: 7px;
  background: white;
  &.locationInfo {
    width: 100%;
    border: 0
  }
`;

export const DataItem = styled.div`
  display: flex;
  gap: 10px;
  font-size: 10px;
  text-align: start;

  div:first-child {
    font-size: 11px;
    font-weight: bold;
    white-space: nowrap;
  }

  div {
    color: #555;
  }
  &.fullSize {
    display: block;
    div:first-child {
      font-size: 24px;
      margin: 8px 0;
      font-weight: bold;
      white-space: nowrap;
    }
    div:last-child {
      font-size: 16px;
      font-style: italic;
    }
    div { 
      color: black
    }
  }
`;

export const DropDownMenu = styled.div`
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
  background: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
`;

export const BanComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const BanContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 180px;
  gap: 5px;
  top: 100%;
  right: 0;
  z-index: 1;
  .ban-content {
    padding: 10px 20px;
  }

  @media (max-width: 1099px){
    right: auto;
  }
`;