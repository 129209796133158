import styled from "styled-components/macro";
import MaterialButton from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";

export const RequestButton = styled(MaterialButton)`
  display: flex;
  position: relative;
  justify-content: flex-start;
  min-width:unset !important;
  .MuiButton-label {
    color: #fff;
    gap: 10px;
  }
  align-items: center;
  color: #fff !important;
  padding: 10px 16px;
  font-size: 12px;
  background: #000 !important;
  &.sidebarClosed {
    justify-content:center;
  }
  @media (min-width:1600px){
    font-size:0.7rem;
  }
  @media only screen and (max-width: 1020px) and (min-width: 951px){
    font-size:10px;
  }
  @media (max-width:950px){
    font-size:0.6em;

    .MuiButton-label {
      color: #fff;
      gap: 5px;
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .MuiButton-label svg{
      width: 16px !important;
      height: 16px !important;
    }

    &.sidebarClosed{
      padding: 9px 1px;
      padding: 4px 15px;
      width: fit-content;
      margin: auto;
    }
  }
`;

export const RequestsCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  z-index: 1;
  color: #fff;
  background: #d02929;
  right: ${({ sidebarClosed }) => (sidebarClosed ? -8.5 : -1.5)}px;
  top: -12.5px;
`;

export const useStyles = makeStyles({
  hideScroll: {
    "& .MuiDialog-paper": {
      overflow: "inherit !important",
    },
  },
});