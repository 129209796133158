import { memo } from "react";
import { useSelector } from "react-redux";
import { ROLES } from "configs/constants";
import { SidebarHeader, MenuListBar } from "components"


export const MenuWithHeader = memo(({sidebarClosed})=> {
  const dataUser = useSelector(({ userPartner }) => userPartner.userData);

  const role = dataUser?.role || ROLES.SUPERADMIN;
  const dashboardImage = dataUser?.dashboardImage || null;
  const isSuperAdmin =  role === ROLES.SUPERADMIN;

  return(
    <>
      <SidebarHeader
        sidebarClosed={sidebarClosed}
        isSuperAdmin={isSuperAdmin}
        role={role}
        dashboardImage={dashboardImage}
      />
      <MenuListBar
        sidebarClosed={sidebarClosed}
        role={role}
        isSuperAdmin={isSuperAdmin}
      /> 
    </>
  )
}, (prevProps, nextProps)=> {
  return prevProps.sidebarClosed === nextProps.sidebarClosed
})