import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getUserPartnersServ,
  loginSuperadminServ,
  loginUserServ,
  forgotPasswordServ,
  resendEmailServ
} from './userApi';
import { meDataServ } from '../app/appApi';
import { setNotification } from '../notification/notificationSlice';


const initialState = {
  loading: false,
  error: null,
  userInfo: null,
  partners: null,
  resetSuccessfully: "",
  resetError: null,
};

export const getUserPartners = createAsyncThunk(
  'user_partners_get',
  async ()=> {
    const res = await getUserPartnersServ();
    if (res.success) {
      return res.data;
    }
    throw new Error('getUserPartners function error', res.message);
  }
);

export const forgotPassword = createAsyncThunk(
  'user_forgot_password',
  async (data)=> {
    const res = await forgotPasswordServ(data);
    if(res.success) {
      return res;
    }
    throw new Error('Invilible email', res.message);
  }
);

export const resendEmail = createAsyncThunk(
  'resend-email', 
  async (data, { dispatch }) => {
    const res = await resendEmailServ(data);
    if(res.success) {
      dispatch(setNotification({type: res.message, message: 'success'}));
      return res;
    }
    dispatch(setNotification({type: res.message, message: 'error'}));
    throw new Error('Delete shortcut failed', res.message);
  }
)

export const login = createAsyncThunk(
  'login',
  async ({isSuperAdmin = false, body})=> {
    const res = isSuperAdmin? await loginSuperadminServ(body) : await loginUserServ(body);
    if(res.success) {
      localStorage.setItem(`${isSuperAdmin ? 'superadmin_token':'access_token'}`, res.data);
      const me = await meDataServ();
      return me.data;
    }
    throw new Error('Wrong email or password', res.message);
  }
)

export const logoutUser = createAsyncThunk(
  'logout',
  async ()=> {
    localStorage.clear();
    return true;
  }
);


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getMeSuccess: (state, action)=> {
      state.loading = false;
      state.userInfo = { ...state.userInfo, ...action.payload };
    },
    getMeFailed: (state)=> {
      state.loading = true;
      state.userInfo = null;
    },
    userIsLoaded: (state)=> {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserPartners.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserPartners.fulfilled, (state, action) => {
        state.loading = false;
        state.partners = action.payload || [];
      })
      .addCase(getUserPartners.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'getPartners'", action.error)
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.resetSuccessfully = action.payload.success;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.resetError = action.error.message;
        state.resetSuccessfully = false;
        console.error("Error redux async func 'forgotPassword'", action.error)
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        console.error("Error redux async func 'login'", action.error);
      })
      .addCase(logoutUser.pending, (state) => {
        state.loading = true
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.loading = false;
        state.userInfo = {};
        state.error = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'logoutUser'", action.error)
      })
      .addCase(resendEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(resendEmail.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(resendEmail.rejected, (state, action) => {
        state.loading = false;
        console.error("Error redux async func 'resendEmail'", action.error)
      })
  },
});

export const {
  getMeSuccess,
  getMeFailed,
  userIsLoaded
} = userSlice.actions;
export const selecUser = (state)=> state.user;
export default userSlice.reducer;