import * as S from "../AgentChat.styles";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "@material-ui/core";
import { Send, AttachFile, Close } from '@mui/icons-material';
import { messageSend, openChatPage } from "socket/socket";
import { messagesSort } from "../../ChatDataList/ChatDataList.config";
import { ChatDataList } from "components";


export const CurrentAgentChat = ({ chatData, agent })=> {  
  const [selectedFile, setSelectedFile] = useState('');
  const [file, setFile] = useState(null);
  const textAreaRef = useRef();

  const messages = messagesSort(chatData.messages || []);

  const handleChangeFile = useCallback((e) => {
    const fileToUpload = e.target.files[0];
    if(fileToUpload.size <= 4000000) {
      setFile(fileToUpload);
      const reader = new FileReader();
      reader.readAsDataURL(fileToUpload);

      reader.onloadend = function(e) {
        setSelectedFile(reader.result);
      }
    }
    e.target.value = null;
  }, []);

  const onEnterPress = (e) => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      send();
    }
  };

  const send = () => {
    const message = textAreaRef.current.value;
    if ((message.trim() || file) && chatData?._id) {
      const newFileObj = [file? file.type:'', file? file:''];
      messageSend(chatData._id, message.trim(), newFileObj);
      setFile('');
      setSelectedFile('');
      textAreaRef.current.value = '';
    }
  };

  useEffect(()=>{
    openChatPage(chatData?._id)
  },[chatData?._id]);

  return(
    <S.ChatContent>
      <ChatDataList
      messagesChat={messages}
      agentName={agent.name}
      chat={chatData}
      />
      {!chatData?.chatEnd && <S.EditorContainer>
          <S.TextArea
            ref={textAreaRef}
            minRows={6}
            onKeyDown={onEnterPress}
          />
          <S.EditorButtonsContainer>
            <Button>
              <S.InputLabel htmlFor="file-input">
                <AttachFile />
              </S.InputLabel>
              <S.FileInput
                accept="video/*, image/*"
                id="file-input"
                max="500"
                type="file"
                onChange={handleChangeFile}
              />
            </Button>
            <Button onClick={send}>
              <Send />
            </Button>
          </S.EditorButtonsContainer>
        </S.EditorContainer>}
        {selectedFile &&
          <S.FileContent>
            <Close onClick={() => {
              setSelectedFile('');
              setFile('');
            }} />
            {file.type?.split('/')[0] === 'image' ? 
              <S.SelectedImage src={selectedFile} /> : <S.SelectedVideo src={selectedFile} />
            }
          </S.FileContent>
        }
    </S.ChatContent>
  )
}