import styled from "styled-components/macro";

export const Drawer = styled.div`
  flex-shrink: 0;
  transition: width 0.3s ease;
  white-space: nowrap;
  position:relative;
  background: #1b1b1b;
  min-width: 222px;

  @media only screen and (max-width: 1399px) and (min-width: 951px) {
    min-width: 217px;
  }

  &.sidebarClosed{
    min-width: 110px;

    @media only screen and (max-width: 1920px) and (min-width: 951px) {
      min-width: 110px;
    }
    @media (max-width:950px){ {
       min-width: 100px;
    }
  }
  
  .MuiDrawer-paper {
    transition: width 0.3s ease;
  }

  @media (min-width:1920px){
    svg{
      width: 24px !important;
      height: 30px !important;
    }
  }

`;

export const Version = styled.div`
  text-align: center;
  color: #d1d1d1;
  font-size: 12px;
`;
export const DevPortal = styled.div`
  text-align: center;
`;

export const RequestsCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  z-index: 1;
  color: #fff;
  background: #d02929;
  right: ${({ sidebarClosed }) => (sidebarClosed ? -8.5 : -1.5)}px;
  top: -12.5px;
`;

export const SidebarFooter = styled.div`
  display: flex;
  gap: 15px;
  position: fixed;
  bottom: 30px;
  width: ${({ sidebarClosed }) => (sidebarClosed ? 110 : 222)}px;
  margin-top: 30px;
  flex-direction: column;

  @media only screen and (max-width: 1399px) and (min-width: 951px){
    width: ${({ sidebarClosed }) => (sidebarClosed ? 110 : 217)}px;
  }
  @media (max-width:950px){
    width: 100px;
  }
`;

export const TopMenuContainer=styled.div`
  position: sticky;
  width: 100%;
  top: 0
`;

export const ImgAvatar = styled.div`
  display: flex;
  width: 49px;
  height: 49px;
  border-radius: 50%;
  background-size: contain;
  position: relative;
  background-position: center;
  &.sidebarClosed {
    width: 30px;
    height: 30px;
    margin-bottom:15px;
  }
`;
