import * as S from './Forms.styles'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextField } from '@material-ui/core'
import { Button } from 'ui'
import { updateWidget } from 'redux_toolkit/reducers/partner/widget/widgetSlice'


export const Forms = () => {
  const dispatch = useDispatch();
  const selectedWidget = useSelector(({ widget }) => widget.config);
  const { partnerId } = useSelector(({ userPartner }) => userPartner.userData);

  const [greeting, setGreeting] = useState(selectedWidget.greetingMessage);
  const [offlineGreeting, setOfflineGreeting] = useState(selectedWidget.offlineGreeting);

  const saveChanges = () => {
    dispatch(updateWidget({partnerId, _id: selectedWidget._id, data: {styles: {...selectedWidget.styles}, greetingMessage: greeting, offlineGreeting}}));
  }

  return (
    <S.FormsContainer>
      <S.LeftPart>
        <S.InfoItemContainer>
          <S.InfoItemTitle>
            <div>
              Pre-chat form
            </div>
            <S.ButtonsContainer>
              <Button
                onClick={() => { setOfflineGreeting(true); }}
                disabled={offlineGreeting}
              >
                On
              </Button>
              <Button
                onClick={() => { setOfflineGreeting(false); }}
                disabled={!offlineGreeting}
              >
                Off
              </Button>
            </S.ButtonsContainer>
          </S.InfoItemTitle>
          <S.InfoItemDescription>
            Require visitors to complain a form before starting chat
          </S.InfoItemDescription>
          <S.RowContainer>
            <S.Label>
              Pre-chat greeting
            </S.Label>
            <TextField
              onChange={(e) => setGreeting(e.target.value)}
              multiline
              disabled={!offlineGreeting}
              inputProps={
                { disabled: !offlineGreeting }
              }
              size='small'
              value={greeting}
              variant='outlined'
            />
          </S.RowContainer>
        </S.InfoItemContainer>
        <S.ActionsContainer>
        <Button onClick={saveChanges}>
          Save Changes
        </Button>
        <Button
          color='secondary'
          onClick={()=> setGreeting(selectedWidget.greetingMessage)}
        >
          Discard Changes
        </Button>
      </S.ActionsContainer>
      </S.LeftPart>  

    </S.FormsContainer>
  )
}