import * as S from './AdminsPage.styles';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPlus } from '@fortawesome/free-solid-svg-icons';
import { tableHead, formatTableData } from './AdminsPage.config';
import { Table  } from 'components';
import { hideModal, showModal } from 'redux_toolkit/reducers/modals/modalsSlice';
import { deleteAdmin } from 'redux_toolkit/reducers/super_admin/admins/adminsSlice';
import { Button } from 'ui';
import { forgotPassword } from 'redux_toolkit/reducers/user/userSlice';
import { resendEmail } from 'redux_toolkit/reducers/user/userSlice';


export const AdminsPage = () => {
  const dispatch = useDispatch();
  const adminsList = useSelector(({ admins }) => admins.list);

  const getTableActions = (admin) => ([
    admin.status === 0 ?
    <Button
    className="assign-admin"
    onClick={() => dispatch(resendEmail({ email: admin.email }))}
    >
      Resend
    </Button> : 
      <Button
      className="assign-admin"
      onClick={() => dispatch(forgotPassword({ email: admin.email }))}
    >
      Reset
    </Button>,
    <S.ActionsContainer>
      <S.ActionButton
        key="edit"
        onClick={() => {
          dispatch(showModal({
            type: 'adminForm',
            adminToEdit: admin
          }
        ))}}
      >
        <EditIcon />
      </S.ActionButton>
      <S.ActionButton
        key='delete'
        onClick={() => dispatch(showModal(
          {
            type: 'confirmation',
            title: `Are you sure you want to delete "${admin.name}" ?`,
            description: "This item will be deleted immediately. You can't undo this action.",
            hideModal: hideModal,
            confirmText: 'Delete',
            onConfirm: () => {
              dispatch(deleteAdmin(admin._id));
            }
          }
        ))}
      >
        <DeleteIcon />
      </S.ActionButton>
    </S.ActionsContainer>
  ])

  function headerButtons() {
    return (
      <S.HeaderContainerActions  onClick={() => dispatch(showModal({type: 'adminForm'}))}>
        <S.Plus>
          <FontAwesomeIcon icon={faPlus} classes='add-agent' />
        </S.Plus>
      </S.HeaderContainerActions>
    )
  }

  return (
    <S.AdminsPageContainer>
      <Table
        tableHead={tableHead}
        tableData={formatTableData(adminsList, getTableActions)}
        cardHeaderText='Admins'
        headerComponent={headerButtons()}
        notFound={adminsList.length > 0? '':'Not Admins'}
      />
    </S.AdminsPageContainer>
  )
}