import styled from 'styled-components/macro';
import EmptyAvatarIcon from 'images/emptyAvatar.png';

export const AvatarIcon = styled.div`
  top: calc(-50px + (50 - 60) * (100vw - 1024px) / (1920 - 1024));
  left: 0;
  right: 0;
  height: 110px;
  width:110px;
  margin: auto;
  background: url(${EmptyAvatarIcon});
  background-size: cover;
  border-radius: 50%;
  position: absolute;
`