import {
  LoginPage,
  ForgotPasswordPage,
  ConfirmPasswordPage,
  HomePage,
  HistoryPage,
  PersonalPage,
  AgentsPage,
  BannedPage,
  ShortcutsPage,
  VisitorsPage,
  WidgetsPage,
  SuperAdminHomePage,
  PartnersPage,
  AdminsPage
} from "pages";

export const notAuthRoots = [
  {path: "login", element: <LoginPage />},
  {path: "forgot-password", element: <ForgotPasswordPage />},
  {path: "confirm-password", element: <ConfirmPasswordPage />},
];

export const authRoots = [
  {path: "home", element: <HomePage />},
  {path: "history", element: <HistoryPage />},
  {path: "personal", element: <PersonalPage />},
  {path: "agents", element: <AgentsPage />},
  {path: "banned", element: <BannedPage />},
  {path: "shortcuts", element: <ShortcutsPage />},
  {path: "visitors", element: <VisitorsPage />},
  {path: "widget", element: <WidgetsPage />}
];

export const superadminRoots = [
  {path: "home", element: <SuperAdminHomePage />},
  {path: "partners", element: <PartnersPage />},
  {path: "admins", element: <AdminsPage />},
];