import cx from "classnames";
import * as S from "./MenuListBar.style";
import { memo, useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuBuilder } from "../Sidebar/Sidebar.config";
import { Team, SubMenu } from "components";
import { changePageName } from "redux_toolkit/reducers/page_name/pageNameSlice";
import { getDefaultId } from "utils/functions";


export const MenuListBar = memo(({
  sidebarClosed,
  isSuperAdmin,
  role
})=> {
  const dispatch = useDispatch();
  const { menuAll } = MenuBuilder();
  const routes = useRef(menuAll[role]);
  const [activePath, setActivePath] = useState(`${window.location.pathname}`);

  const changeTab = useCallback((path)=>{
    setActivePath(path);
    dispatch(changePageName(path.split('/')[2]));
  }, [dispatch])

  return(
    <S.List className={cx({ sidebarClosed, isSuperAdmin })}>
      {routes.current?.map((item) => (
        <div key={getDefaultId()}>
          {item.isSubMenu ? (
            <SubMenu
              item={item}
              sidebarClosed={sidebarClosed}
              activePath={activePath}
              changeTab={changeTab}
            />
          ) : (
            <S.ListItem
              selected={activePath === item.path}
              button
              component={Link}
              to={item.path}
              className={cx({ sidebarClosed })}
              onClick={()=> { changeTab(item.path) }}
            >
              <S.ListItemIcon>
                <FontAwesomeIcon icon={item.icon} />
              </S.ListItemIcon>

              {!sidebarClosed && (
                <S.ListItemText
                  primary={item.name}
                  className={cx({ sidebarClosed })}
                />
              )}
            </S.ListItem>
          )}
        </div>
      ))}
      {!isSuperAdmin && <Team sidebarClosed={sidebarClosed} />}
    </S.List>
  )
}, (prevProps, nextProps)=> {
  return(
    prevProps.sidebarClosed === nextProps.sidebarClosed &&
    prevProps.isSuperAdmin === nextProps.isSuperAdmin &&
    prevProps.activePath === nextProps.activePath &&
    prevProps.role === nextProps.isSuperAdmin
  );
})