import cx from 'classnames'
import * as S from "../AuthLayout.styles";
import { useState } from "react";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ROLES } from "configs/constants";
import { ChatTabs } from "components";
import { HeaderAuthLayout, ViewChats } from 'components';


export const LayoutContainer = ()=> {
  const { isSidebarOpened } = useSelector(({ app }) => app);
  const currentUser = useSelector(({ userPartner })=> userPartner.userData);

  const isSuperAdmin = !currentUser?.role;
  const isAdmin = (currentUser?.role === ROLES.ADMIN);

  const [anchorEl, setAnchorEl] = useState(null);
  const isChatsListOpened = Boolean(anchorEl);

  const isAgent = (currentUser?.role === ROLES.AGENT);
  const showDrawerToggle = window.innerWidth <= 1024 || window.innerWidth >= 1920;

  const chatsListButtonId = isChatsListOpened ? "chats-list-popover" : undefined;

  return(
    <>
      {(!isSuperAdmin && !isAgent) && (
        <S.ChatsButton
          showdrawertoggle={showDrawerToggle.toString()}
          opened={!isSidebarOpened ? 270 : 100}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          className={cx({ sidebarClosed: !isSidebarOpened })}
        >
          <VisibilityIcon aria-describedby={chatsListButtonId} />
        </S.ChatsButton>
      )}
      <S.ChatsListPopover
        id={chatsListButtonId}
        open={isChatsListOpened}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <ViewChats currentUser={currentUser} isSidebarOpened={isSidebarOpened} />
      </S.ChatsListPopover>
      <HeaderAuthLayout currentUser={currentUser} isSuperAdmin={isSuperAdmin} />
      {!isSuperAdmin && !isAdmin && <ChatTabs />}
    </>
  )
}