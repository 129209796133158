import * as S from "./HistoryPage.styles";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getLimit } from "components/Table/Table.config";
import { Loader, HistorySearch, HistoryList } from "components";

export const HistoryPage = () => {
  const { count: historyCount, list: chatsHistory, loading } = useSelector(({ history }) => history);
  const { partnerId } = useSelector(({ userPartner }) => userPartner.userData);

  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [historyDetailsItem, setHistoryDetailsItem] = useState(null);
  const [historyList, setHistoryList] = useState([]);
  const itemLimit = getLimit() || 10;

  useEffect(() => {
    setHistoryList(chatsHistory || []);
  }, [chatsHistory]);

  return (
    <S.HistoryPageContainer>
      <HistorySearch
        setSelectedRowIndex={setSelectedRowIndex}
        setHistoryDetailsItem={setHistoryDetailsItem}
        partnerId={partnerId}
        itemLimit={itemLimit}
        historyCount={historyCount}
        historyList={historyList}
      />
      {loading ? <Loader color="primary" size={100} /> :
        <HistoryList
        setSelectedRowIndex={setSelectedRowIndex}
        setHistoryDetailsItem={setHistoryDetailsItem}
        partnerId={partnerId}
        historyDetailsItem={historyDetailsItem}
        selectedRowIndex={selectedRowIndex}
        historyList={historyList}
        notFound={!historyCount ? 'Not Found' : ''}
      />}
    </S.HistoryPageContainer>
  );
};
