import * as S from "./StatusSelect.styles";
import * as SP from "../ForSidebar/Sidebar/Sidebar.styles";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES_LIST } from "configs/constants";
import { ROLES } from "configs/constants";
import { SelectBox } from "ui";
import { agentStatusUpdate } from "socket/socket";
import { updateUserData } from "redux_toolkit/reducers/user_partner/userPartnerSlice";


export const StatusSelect = memo(({
  partner,
  valueContainerStyles,
  indicatorContainerStyles = {},
  controlStyles,
  setStatus,
  avatarImg
}) => {
  const dispatch = useDispatch()
  const user = useSelector(({ userPartner }) => userPartner.userData);

  if (!partner || partner.role === ROLES.ADMIN) return null;
  const statusUser = STATUSES_LIST.find(item=> item.value === user?.status) || STATUSES_LIST[2];

  const onSelectType = (e) => {
    if(setStatus) return setStatus(e.value);
    dispatch(updateUserData({ status: e.value }));
    agentStatusUpdate(e.value)
  }

  const SingleValue = (props) => {
    return (
      <>
        {
          avatarImg ? <SP.ImgAvatar className={avatarImg.className} style={avatarImg.style}>
            <div style={S.styles.singleValueStyles(props)} />
          </SP.ImgAvatar>
          : <div style={S.styles.singleValueStyles(props)} />
        }
      </>
    )
  };

  return (
    <SelectBox
      list={STATUSES_LIST}
      onChange={onSelectType}
      getOptionLabel={(option) => {
        return (
          <S.Container>
            <S.TypeIcon color={option.color} />
            {option.label}
          </S.Container>
        )
      }}
      borderColor="transparent"
      boxShadow="none"
      components={{ SingleValue }}
      valueContainerStyles={valueContainerStyles || S.styles.valueContainerStyles}
      optionStyles={S.styles.optionStyles}
      controlStyles={controlStyles || S.styles.controlStyles}
      indicatorContainerStyles={indicatorContainerStyles}
      defaultValue={statusUser?.id}
      avatarImg={avatarImg}
    />
  )
}, (prevProps, nextProps)=> {
  return(
    prevProps.partner === nextProps.partner &&
    prevProps.valueContainerStyles === nextProps.valueContainerStyles &&
    prevProps.indicatorContainerStyles === nextProps.indicatorContainerStyles &&
    prevProps.controlStyles === nextProps.controlStyles &&
    prevProps.avatarImg === nextProps.avatarImg &&
    prevProps.setStatus === nextProps.setStatus
  );
});
