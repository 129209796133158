export const debounce = (func, wait)=> {
  let timeout;
  return function executedFunction(...args) {
    clearTimeout(timeout);

    timeout = setTimeout(()=> {
      clearTimeout(timeout);
      func(...args);
    }, wait);
  };
}

export const compact = (array)=> {
  return array.filter(Boolean);
}

export const isEmpty = (value)=> {
  if (value === null || value === undefined) return true;

  if (Array.isArray(value) || typeof value === 'string' || value instanceof Map || value instanceof Set) return value.length === 0;

  if (typeof value === 'object')  return Object.keys(value).length === 0;

  return !value;
}

export const upperFirst = (str)=> {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const dropRight = (array, n = 1)=> {
    if (!Array.isArray(array) || array.length === 0 || n <= 0) return [];
    return array.slice(0, array.length - n);
}

export const omit = (obj, keys) => {
  const newObj = {};
  for (let key in obj) {
    if (!keys.includes(key)) newObj[key] = obj[key];
  }
  return newObj;
};

export const uniqBy = (array, iteratee)=> {
  const seen = new Set();
  return array.filter(item => {
    const key = typeof iteratee === 'function' ? iteratee(item) : item[iteratee];
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
}

export const getDefaultId = ()=> ((Math.random()*10)+(Math.random()*100));

export const findStringItem = (arr, text)=> {
  return arr.find(item => text.toLowerCase().includes(item));
};

export const sortArrObjByItem = (arr, item)=> {
  return arr?.sort((a, b) => a[item].localeCompare(b[item]));
}