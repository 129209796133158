import * as S from './WidgetsPage.styles'
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from 'components'
import { Tabs } from './Tabs/Tabs'
import { SelectBox } from "ui";
import { getWidgets, setSelectedWidget } from 'redux_toolkit/reducers/partner/widget/widgetSlice'


export const WidgetsPage = () => {
  const dispatch = useDispatch()
  const {list: widgets, config: selectedWidget, loading } = useSelector(({widget})=> widget);
  const { partnerId } = useSelector(({userPartner})=> userPartner.userData);

  const setSelectedWidgetFunc = (val) => dispatch(setSelectedWidget(val));
  
  const [names, setNames] = useState([])
  const memoizeGetWidgets = useCallback(() => dispatch(getWidgets(partnerId)), [partnerId, dispatch]);

  useEffect(() => {
    !widgets.length && memoizeGetWidgets();
    let names = widgets.map(el => {
      return {
        label: el.name,
        id: el._id,
        value: el._id,
      }
    })
    setNames(names)

  }, [widgets, memoizeGetWidgets])

  const widget = useMemo(() => {
    return {
      label: selectedWidget.name,
      value: selectedWidget._id,
      id: selectedWidget._id,
    }
  }, [selectedWidget])

  return (
    <>
      <S.SelectWrapper>
        <SelectBox
          onChange={(e) => { setSelectedWidgetFunc(e.value) }}
          list={names}
          defaultValue={widget}
          value={widget}
          controlStyles={{ background: 'white' }}
        />
        <p>Select widget</p>
      </S.SelectWrapper>
      <S.WidgetsPageContainer>
        <S.Paper>
          <Tabs />
        </S.Paper>
      </S.WidgetsPageContainer>
      {loading && <Loader color="primary" size={100} />}
    </>
  )
}