import React, { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ClientChat } from 'components'
import { openChatPage } from 'socket/socket';
import { showModal } from 'redux_toolkit/reducers/modals/modalsSlice'


export const VisitorInfo = memo(({
  visitor,
  hideModal
}) => {
  const { chats, watchingChats } = useSelector(({ chat }) => chat);
  const getChatByVisitorId = (chatId, list)=> [...list.values()].find((chat)=> chat._id === chatId);
  const chat = getChatByVisitorId(visitor.chatId, chats);
  const dataChat = chat || getChatByVisitorId(visitor.chatId, watchingChats);

  useEffect(()=>{
    if(chat?._id) openChatPage(chat._id);
  },[chat?._id]);

  return (
    <>
      {dataChat && <ClientChat
        canEdit={chat?.messages ? true : false}
        chatId={dataChat._id}
        hideModal={hideModal}
        showModal={showModal}
      />}
    </>
  )
}, (prevProps, nextProps)=> {
  return(
    prevProps.visitor === nextProps.visitor &&
    prevProps.hideModal === nextProps.hideModal
  )
})