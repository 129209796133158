import cx from "classnames";
import * as S from "./RequestButtons.style";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ROLES } from "configs/constants";
import { getChat, openChatPage } from "socket/socket";
import { showModal } from "redux_toolkit/reducers/modals/modalsSlice";


export const RequestButtons = memo(({
  currentPartner,
  sidebarClosed,
  role
})=> {
  const dispatch = useDispatch();
  const styles = S.useStyles();

  const { chats, chatRequests } = useSelector(({ chat }) => chat);

  const [incomingCounts, setIncomingCounts] = useState(chatRequests.size);
  const [modalOpened, setModalOpened] = useState(false);
  const incomingVisitsRef = useRef();

  const allChats = useMemo(() => [...chats.values()], [chats]);

  const handleOpenTransferModal = useCallback(() => {
    const { agentName, visitorName, chatId } = currentPartner?.transferRequests[0];
    dispatch(showModal({
        type: 'transferChat',
        agentName,
        visitorName,
        chatId,
        className: styles.hideScroll
      }
    ));
  }, [currentPartner?.transferRequests, styles.hideScroll, dispatch]);


  const handleOpenModal = useCallback(() => {
    incomingVisitsRef.current = chatRequests.values().next().value;

    getChat(incomingVisitsRef.current);
    setModalOpened(true);
  }, [chatRequests]);

  const openServeChat = useCallback(
    async (e) => {
      if (e.ctrlKey && e.keyCode === 192 && incomingCounts > 0) {
        handleOpenModal();
      }
    },
    [incomingCounts, handleOpenModal]
  );

  useEffect(() => {
    setIncomingCounts(chatRequests.size);
  }, [chatRequests.size]);

  useEffect(() => {
    const currentChat = allChats.find(
      (chat) => chat._id === incomingVisitsRef.current
    );
    if (modalOpened && currentChat) {
      openChatPage(currentChat._id);
      dispatch(showModal({
        type: 'clientChat',
        chatId: currentChat._id,
        showOne: true
      }));
      setModalOpened(false);
    }
  }, [allChats, modalOpened, dispatch]);

  useEffect(() => {
    window.removeEventListener("keydown", openServeChat);
    window.addEventListener("keydown", openServeChat);
    return () => {
      window.removeEventListener("keydown", openServeChat);
    };
  }, [openServeChat]);

  return(
    <>
      <S.RequestButton
        disabled={incomingCounts === 0}
        onClick={handleOpenModal}
        className={cx({ sidebarClosed })}
      >
        {sidebarClosed ? (
          <FontAwesomeIcon icon={faComment} />
        ) : (
          <>
            <FontAwesomeIcon icon={faComment} />
            <span>Chat Request</span>
          </>
        )}
        {incomingCounts > 0 && (
          <S.RequestsCount>{incomingCounts}</S.RequestsCount>
        )}
      </S.RequestButton>
      {role === ROLES.TEAMLEADER && (
        <S.RequestButton
          disabled={!currentPartner?.transferRequests?.length}
          className={cx({ sidebarClosed })}
          onClick={handleOpenTransferModal}
        >
          {role === ROLES.TEAMLEADER && sidebarClosed ? (
            <FontAwesomeIcon icon={faExchangeAlt} />
          ) : role === ROLES.TEAMLEADER && !sidebarClosed ? (
            <>
              <FontAwesomeIcon icon={faExchangeAlt} />
              <span>Transfer Request</span>
            </>
          ) : null}
          {currentPartner && currentPartner.transferRequests?.length ? (
            <S.RequestsCount>
              {currentPartner.transferRequests.length}
            </S.RequestsCount>
          ):''}
        </S.RequestButton>
      )}
    </>
  )
}, (prevProps, nextProps)=> {
  return(
    prevProps.currentPartner === nextProps.currentPartner &&
    prevProps.sidebarClosed === nextProps.sidebarClosed &&
    prevProps.role === nextProps.role
  );
})