import cn from 'classnames';
import * as S from './CurrentChat.style';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Send, AttachFile, Close } from '@mui/icons-material';
import { Button } from '@material-ui/core'
import { messageSend } from 'socket/socket';
import { messagesSort } from 'components/ForChat/ChatDataList/ChatDataList.config';
import { ChatDataList } from 'components';
import { FILE_PARTNERS_TYPE } from 'configs/constants';


export const CurrentChat = memo(({ canEdit, chatData, partnerFile })=> {
  const {list: shortcuts} = useSelector(({ shortcut }) => shortcut);

  const [wordToCompile, setWordToCompile] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [file, setFile] = useState(null);
  const [filteredShortcuts, setFilteredShortcuts] = useState(shortcuts || []);
  const [activeShortcut, setActiveShortcut] = useState(null);
  const [selectedShortcut, setSelectedShortcut] = useState(null);
  const [textDirection, setTextDirection] = useState('ltr');
  const [currentIndex, setCurrentIndex] = useState(0);
  const shortcutsContainer = useRef();
  const isShortcutWord = useRef(false);
  const textAreaRef = useRef();

  const onKeyPress = useCallback((e, chatId) => {
    if (e.key === 'Enter' && e.shiftKey === false && (e.target.value.trim() || file) && canEdit) {
      e.preventDefault();
      if(!isShortcutWord.current && !selectedShortcut) {
        const newFileObj = [file? file.type:'', file? file:''];
        messageSend(chatId, e.target.value.trim(), newFileObj);
        setFile('');
        setSelectedFile('');
      }
      if(selectedShortcut) {
        messageSend(chatId, arrangeShortcut(selectedShortcut))
      }

      isShortcutWord.current = false;
      textAreaRef.current.value = '';
    }
  }, [canEdit, selectedShortcut, file]);

  const handleChange = useCallback((e) => {
    const char = e.target.value.trim();
    if (char.charAt(0) === '/') {
      isShortcutWord.current = true;
      setCurrentIndex(0);
    }

    if(char.charAt(0) !== '/' && isShortcutWord.current) {
      isShortcutWord.current = false;
    }

    if (isPersian(char.charAt(0))) {
      setTextDirection('rtl');
    } else {
      setTextDirection('ltr');
    }

    if (shortcutsContainer.current) {
      shortcutsContainer.current.scrollTo(0,0);
    }

    if (selectedShortcut) {
      setSelectedShortcut(null);
      setActiveShortcut(null);
    }
  }, [selectedShortcut]);

  const handleKeyUp = useCallback((e) => {
    if (activeShortcut || e.target.value.trim().charAt(0) === '/') {
      const words = e.target.value.trim().split(' ');
      setWordToCompile(words[0].slice(1));

      if (words.length !== 1 || !e.target.value.trim()) {
        isShortcutWord.current = false;
        setWordToCompile('');
        setActiveShortcut(null);
      }

      if (e.key === 'ArrowDown' && filteredShortcuts.length - 1 !== currentIndex && shortcutsContainer.current) {
        shortcutsContainer.current.scrollTo(0, currentIndex * 36);
        setCurrentIndex(prev => prev + 1);
      }

      if (e.key === 'ArrowUp' && currentIndex !== 0 && shortcutsContainer.current) {
        const Y = shortcutsContainer.current.scrollHeight - (36 * (filteredShortcuts.length + 1 - currentIndex));
        shortcutsContainer.current.scrollTo(0, Y);
        setCurrentIndex(prev => prev - 1);
      }

      if (e.key === 'Enter' && filteredShortcuts.length) {
        setSelectedShortcut(activeShortcut);
        setActiveShortcut(null);
        setCurrentIndex(0);
        textAreaRef.current.value = arrangeShortcut(activeShortcut);
      }
    }
  }, [currentIndex, filteredShortcuts.length, activeShortcut]);

  const handleSendShortcut = useCallback((shortcut) => {
    textAreaRef.current.value = arrangeShortcut(shortcut);
    textAreaRef.current.focus();
    setSelectedShortcut(activeShortcut);
    setActiveShortcut(null);
    setCurrentIndex(0);
    setWordToCompile('');
    isShortcutWord.current = false;
  }, [activeShortcut]);

  const handleChangeFile = (e) => {
    const fileToUpload = e.target.files[0];
    if(fileToUpload.size <= 4000000) {
      setFile(fileToUpload);
      const reader = new FileReader();
      reader.readAsDataURL(fileToUpload);

      reader.onloadend = function(e) {
        setSelectedFile(reader.result);
      }
    }
    e.target.value = null;
  };

  useEffect(() => {
    if (filteredShortcuts.length && isShortcutWord.current) {
      setActiveShortcut(filteredShortcuts[currentIndex]);
    }
  }, [filteredShortcuts, currentIndex]);

  useEffect(() => {
    if(isShortcutWord.current) {
      setFilteredShortcuts(shortcuts?.filter(shortcut => shortcut.tag.includes(wordToCompile)))
    }
  }, [shortcuts, wordToCompile]);

  const send = () => {
    const message = textAreaRef.current.value;
    if (message.trim() || file) {
      const newFileObj = [file? file.type:'', file? file:''];
      messageSend(chatData._id, message.trim(), newFileObj);
      setFile('');
      setSelectedFile('');
      textAreaRef.current.value = '';
    }
  };

  const arrangeShortcut = (data)=> {
    let msg = data.message;
    data?.options.forEach(option => {
      msg += `\n[option]: ${option}`
    })
    return msg;
  }

  function isPersian(char) {
    const persianCharPattern = /[\u0600-\u06FF]/;
    return persianCharPattern.test(char);
  }

  return(
    <S.ChatContent>
      <ChatDataList
      messagesChat={messagesSort(chatData.messages)}
      chat={chatData}
      />
      {canEdit && !chatData.chatEnd &&
        <S.EditorContainer>
          <S.TextArea
            id="message-area"
            ref={textAreaRef}
            minRows={6}
            style={{ direction: textDirection }}
            onKeyDown={(e)=> {
              onKeyPress(e, chatData._id);
            }}
            onKeyUp={(e)=> {
              handleKeyUp(e)
            }}
            onChange={(e) => handleChange(e)}
          />
          {isShortcutWord.current && wordToCompile &&
            <S.ShortcutSelect>
              <S.ShortcutContainer ref={shortcutsContainer}>
                {filteredShortcuts.map((shortcut, index) =>
                  <S.MenuItem
                    key={index}
                    className={cn({isSelected: shortcut?._id === activeShortcut?._id})}
                    onClick={() => {
                      handleSendShortcut(shortcut)
                    }}
                  >
                    {shortcut.message}
                  </S.MenuItem>)
                }
              </S.ShortcutContainer>
            </S.ShortcutSelect>
          }
          <S.EditorButtonsContainer>
            {partnerFile !== FILE_PARTNERS_TYPE.DISABLE &&
              <Button>
                <S.InputLabel htmlFor="file-input">
                  <AttachFile />
                </S.InputLabel>
                <S.FileInput
                  accept="video/*, image/*"
                  id="file-input"
                  max="500"
                  type="file"
                  onChange={handleChangeFile}
                  />
              </Button>
            }
            <Button onClick={send}>
              <Send />
            </Button>
          </S.EditorButtonsContainer>
        </S.EditorContainer>
      }
      {selectedFile &&
        <S.FileContent>
          <Close onClick={() => {
            setSelectedFile('');
            setFile('');
          }} />
          {file.type?.split('/')[0] === 'image'  ?
            <S.SelectedImage src={selectedFile} /> : <S.SelectedVideo src={selectedFile} />
          }
        </S.FileContent>
      }
    </S.ChatContent>
  )
}, (prevProps, nextProps)=> {
  return(
    prevProps.canEdit === nextProps.canEdit &&
    prevProps.chatData === nextProps.chatData &&
    prevProps.partnerFile === nextProps.partnerFile
  )
})